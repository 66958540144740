
const IT4D = 1
const ADMIN_CLIENT = 2
const USER_CLIENT = 3 
const USER_UNIT = 4

const  PREMISSIONS = {
    MASTER : [IT4D],
    ADMIN :  [IT4D, ADMIN_CLIENT],
    USER : [ IT4D, ADMIN_CLIENT, USER_CLIENT],
    ADMIN_UNIT : [ USER_UNIT, IT4D, ADMIN_CLIENT, USER_CLIENT ],
}


export default PREMISSIONS;