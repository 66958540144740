import Crud from '../views/MobilePosition/Index'
import VideoWall from '../views/MobilePosition/VideoWall'
import Remotions from '../views/MobilePosition/Remotions'

let routes = {
    path: '/mobile_position',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/mobile_position',
            name: 'mobile-position',
            component: Crud,
            meta: {
                pageTitle: 'Programação do Dia',                
                access_level: ['1','2','3','4'],
                breadcrumb: [
                    {
                        text: 'Programação do Dia',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/mobile_position/videowall',
            name: 'videowall',
            component: VideoWall,
            meta: {
                pageTitle: 'Posições em Tempo Real',          
                access_level: ['1','2','3','4'],
                breadcrumb: [
                    {
                        text: 'Posições em Tempo Real',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/remotions',
            name: 'remotions',
            component: Remotions,
            meta: {
                pageTitle: 'Remoções',          
                access_level: ['1','2','3','4'],
                breadcrumb: [
                    {
                        text: 'Remoções',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
