<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'activitiesCrud'"
          :cruduser_id="$t('Activity')"
          :newText="$t('Add') + '  ' + $t('New')"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
          :extra="extra"
          @restart="restart"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "activitiesTable",
      showMessageImport: false,
      activitiesCrud: {},
      pages: [],
      url: "",
      columns: [
        "id",
        "user_id",
        "vehicle_id",
        "status",
        "start_date",
        "end_date",
        "actual_start_date",
        "actual_end_date",
        "actions",
      ],
      tableData: [
        "id",
        "user_id",
        "area_id",
        "vehicle_id",
        "status",
        "start_date",
        "end_date",
        "actual_start_date",
        "actual_end_date",
      ],
      optionsOrigin: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["user_id", "status"],
        pagination: {
          chunk: 10,
          dropdown: false,
          nav: "scroll",
        },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: this.$t("id"),
          user_id: this.$t("User"),
          vehicle_id: this.$t("Vehicle"),
          start_date: this.$t("start_date"),
          end_date: this.$t("end_date"),
          actual_start_date: this.$t("actual_start_date"),
          actual_end_date: this.$t("actual_end_date"),
          actions: this.$t("action"),
        },

        texts: {
          filterBy: this.$t("Filter"),
          defaultOption: this.$t("Select"),
        },
        listColumns: {
          status: [
            {
              id: 0,
              text: "Não Iniciado",
            },
            {
              id: 1,
              text: "Iniciado",
            },
            {
              id: 2,
              text: "Finalizado",
            },
          ],
        },
        templates: {
          user_id: function (h, row, index) {
            return row.user ? row.user.name : "";
          },
          vehicle_id: function (h, row, index) {
            return row.vehicle ? row.vehicle.name : "";
          },
          status: function (h, row, index) {
            if (row.status == 0) {
              return (
                <h5>
                  <span class="badge badge-warning">Não iniciado</span>
                </h5>
              );
            }
            if (row.status == 1) {
              return (
                <h5>
                  <span class="badge badge-primary">Iniciado</span>
                </h5>
              );
            }
            if (row.status == 2) {
              return (
                <h5>
                  <span class="badge badge-success">Finalizado</span>
                </h5>
              );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};
          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["user", "vehicle"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud view",
          title: "Reiniciar Atividade",
          name: "restart",
          icon: "fas fa-clock",
        },
      ],
      endPoint: "activities",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  computed: {
    options() {
      const headings = {
        id: this.$t("id"),
        user_id: this.$t("User"),
        vehicle_id: this.$t("Vehicle"),
        start_date: this.$t("start_date"),
        end_date: this.$t("end_date"),
        actual_start_date: this.$t("actual_start_date"),
        actual_end_date: this.$t("actual_end_date"),
        actions: this.$t("action"),
      };
      const texts = {
        filterBy: this.$t("Filter"),
        defaultOption: this.$t("Select"),
      };
      return {
        ...this.optionsOrigin,
        texts,
        headings,
      };
    },
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("fileImport", "");

      return fd;
    },
    restart(props, row, index) {
      const self = this;
      let api = self.$store.state.api + "restart_activity/" + props.row.id;

      axios
        .post(api)
        .then((response) => {
          self.$message(
            "Sucesso",
            "Informações guardadas com sucesso",
            "success"
          );
          this.$router.push("/activities");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    query: function (query) {
      let columns = {
        id: this.$t("id"),
        user_id: "user[name]",
        status: this.$t("status"),
        actions: this.$t("action"),
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {},
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
