<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="title bold">Remoções</div>
      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-4">
            <label>Destino</label>
            <v-select
              :options="destinos"
              :reduce="(destinos) => (destinos ? destinos.destino_id : null)"
              :label="'destino_nome'"
              v-model="filters.destination_hospital"
              class="vselect"
              v-on:input="getEntity()"
            />
          </div>
          <div class="col-md-2">
            <label>Data</label>
            <input
              type="date"
              class="form-control"
              v-model="filters.created_at"
              v-on:input="getEntity()"
            />
          </div>
          <div class="col-md-2">
            <label>Número da Ficha</label>
            <input
              type="text"
              class="form-control"
              v-model="filters.card_number"
              v-on:input="debounceCard()"
            />
          </div>
        </div>

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Número Ficha</th>
              <th scope="col">Destino</th>
              <th scope="col">Usuário</th>
              <th scope="col">Veículo</th>
              <th scope="col">Início da Remoção</th>
              <th scope="col">Fim da Remoção</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="removal in removals" :key="removal.id">
              <th scope="row">{{ removal.id }}</th>
              <td>{{ removal.card_number }}</td>
              <td>{{ removal.destination_hospital }}</td>
              <td>{{ removal.user.name }}</td>
              <td>{{ removal.vehicle.name }}</td>
              <td>{{ removal.start_date }}</td>
              <td>{{ removal.end_date }}</td>
              <td>
                <span
                  :class="
                    removal.status == 1
                      ? 'badge badge-success'
                      : 'badge badge-warning'
                  "
                  >{{ removal.status == 1 ? "Finalizado" : "Iniciado" }}</span
                >
              </td>
              <td>
                <button class="btn btn-add" @click="getPositions(removal)">
                  <i class="fas fa-map-marker-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal Mapa -->
    <div
      class="modal fade"
      id="modalMap"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalMapTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Informações do dispositivo
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div ref="mapContainer" class="map-wrapper">
                  <GmapMap
                    :zoom="13"
                    :center="currentLocation"
                    style="width: 100%; height: 700px"
                  >
                    <!-- Ícone do primeiro ponto -->
                    <GmapMarker
                      v-if="positions.length > 0"
                      :icon="
                        require('@/assets/images/logo/icons8-mapa-start-24.svg')
                      "
                      :position="positions[0]"
                    />

                    <!-- Ícone do último ponto -->
                    <GmapMarker
                      v-if="positions.length > 1"
                      :icon="
                        require('@/assets/images/logo/icons8-mapa-end-24.svg')
                      "
                      :position="positions[positions.length - 1]"
                    />

                    <GmapMarker
                      v-for="(position, index) in positions.slice(1, -1)"
                      :key="index"
                      :icon="require('@/assets/images/logo/icons8-bola-24.svg')"
                      :position="position"
                    />

                    <GmapPolyline
                      :path="positions"
                      :options="{
                        strokeColor: '#4169e1',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                      }"
                    />
                  </GmapMap>

                  <!-- Overlay transparente para capturar o mapa -->
                  <div class="map-overlay"></div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <span class="bold"> Legenda: </span>Ícone de Posição Inicial:
                <img
                  src="@/assets/images/logo/icons8-mapa-start-24.svg"
                  width="20"
                />
                Ícone de Posição Final:
                <img
                  src="@/assets/images/logo/icons8-mapa-end-24.svg"
                  width="20"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 bold">Histórico de Posições</div>

              <div class="col">
                <button
                  class="btn btn-secondary mt-2"
                  @click="exportPDF()"
                  :disabled="isLoading"
                >
                  {{ isLoading ? "Exportando..." : "Exportar PDF" }}
                </button>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Latitude</th>
                      <th scope="col">Longitude</th>
                      <th scope="col">Data/Hora</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="pos in positions" :key="pos.id">
                      <th scope="row">{{ pos.id }}</th>
                      <td>{{ pos.lat }}</td>
                      <td>{{ pos.lng }}</td>
                      <td>{{ pos.created_at_f }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Mapa -->
    <div
      class="modal fade"
      id="modalRemovals"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalRemovalsTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Remoções</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Número Ficha</th>
                  <th scope="col">Destino</th>
                  <th scope="col">Usuário</th>
                  <th scope="col">Veículo</th>
                  <th scope="col">Início da Remoção</th>
                  <th scope="col">Fim da Remoção</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="removal in data_modal_removal" :key="removal.id">
                  <th scope="row">{{ removal.id }}</th>
                  <td>{{ removal.card_number }}</td>
                  <td>{{ removal.destination_hospital }}</td>
                  <td>{{ removal.user.name }}</td>
                  <td>{{ removal.vehicle.name }}</td>
                  <td>{{ removal.start_date }}</td>
                  <td>{{ removal.end_date }}</td>
                  <td>
                    <span
                      :class="
                        removal.status == 1
                          ? 'badge badge-success'
                          : 'badge badge-warning'
                      "
                      >{{
                        removal.status == 1 ? "Finalizado" : "Iniciado"
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      timeLeft: 20,
      intervalId: null,
      center_id: null,
      unit_id: null,
      filterStatus: null,
      filterStatus_: null,
      intervalIdChronometer: null,
      auto_refresh: false,
      activities: [],
      centers: [],
      destinos: [],
      removals: [],
      positions: [],
      units: [],
      data_modal_removal: [],
      vehicleslist: [], // Lista de veículos a ser exibida no mapa
      data_modal: {},
      dashboard: {},
      selectedVehicle: {},
      currentLocation: {},
      filters: {},
      selectedRemoval: null,
      isLoading: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    debounceCard: debounce(function () {
      this.getEntity();
    }, 500),
    getCurrentLocation() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log("Localização atual:", this.currentLocation);
          },
          (error) => {
            console.error("Erro ao obter localização:", error);
            this.error = "Não foi possível acessar a localização.";
          }
        );
      } else {
        this.error = "Geolocalização não suportada pelo navegador.";
      }
    },
    getPositions(obj) {
      const self = this;
      let api = self.$store.state.api + "get-remotion/positions";
      self.$loading(true);

      // Armazena a remoção atual no data()
      self.selectedRemoval = obj;

      axios
        .post(api, { obj: obj })
        .then((response) => {
          self.positions = response.data.map((element) => ({
            id: element.id, // ✅ Mantém o ID
            lat: Number(element.lat),
            lng: Number(element.long),
            created_at_f: element.created_at_f, // ✅ Mantém a data formatada
          }));

          setTimeout(() => {
            $("#modalMap").modal("show");
          }, 600);
          self.$loading(false);
        })
        .catch((error) => {
          self.$error(error);
          self.$loading(false);
        });
    },

    exportPDF() {
      if (!this.selectedRemoval) {
        console.error("Nenhuma remoção selecionada!");
        return;
      }

      const self = this;
      let api = self.$store.state.api + "export-remotions-pdf";
      self.isLoading = true;
      self.$loading(true);

      setTimeout(() => {
        const mapElement = self.$refs.mapContainer;

        if (!mapElement) {
          console.error("Mapa não encontrado!");
          self.isLoading = false;
          self.$loading(false);
          return;
        }

        html2canvas(mapElement, {
          useCORS: true,
          scale: 0.5,
        })
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");

            if (!imgData || imgData === "data:,") {
              console.error("Erro ao capturar o mapa.");
              self.isLoading = false;
              self.$loading(false);
              return;
            }

            let formData = new FormData();
            formData.append("mapImage", imgData);
            formData.append("removal", JSON.stringify(self.selectedRemoval));

            axios
              .post(api, formData, { responseType: "blob", timeout: 300000 }) // 5 minutos
              .then((response) => {
                let blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                link.click();
                self.$loading(false);
              })
              .catch((error) => {
                self.$error(error);
              })
              .finally(() => {
                self.isLoading = false; // Habilita o botão após a requisição
                self.$loading(false);
              });
          })
          .catch((err) => {
            console.error("Erro ao capturar imagem do mapa:", err);
            self.$loading(false);
            self.isLoading = false;
          });
      }, 500); // Aguarda 500ms para garantir que o modal desapareça antes de capturar
    },
    getEntity() {
      const self = this;
      let api = self.$store.state.api + "get-remotions";
      self.$loading(true);

      axios
        .post(api, self.filters)
        .then((response) => {
          self.removals = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$error(error);
          this.$loading(false);
        });
    },
    getDestinos() {
      const self = this;

      axios
        .get("https://api.carnaval2025.it4d.com.br/api/destinos/ativos")
        .then((response) => {
          self.destinos = response.data;
        })
        .catch((error) => {
          self.$error(error);
          this.$loading(false);
        });
    },
  },
  mounted() {
    const self = this;

    self.getEntity();
    self.getCurrentLocation();
    self.getDestinos();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.color-success {
  color: green;
}
.color-warning {
  color: rgb(212, 185, 62);
}
.color-danger {
  color: red;
}
</style>
