<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-primary`" size="35">
              <feather-icon size="21" :icon="'FileIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.total }}
              </h2>
              <span>Total de Atividades</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="35">
              <feather-icon size="21" :icon="'FileIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.status_0 }}
              </h2>
              <span> Não Iniciadas </span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="35">
              <i class="fas fa-mobile-alt" style="font-size: 20px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.last_position_recent }}
              </h2>
              <span> Sincronizando </span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="35">
              <i class="fas fa-mobile-alt" style="font-size: 20px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.last_position_old }}
              </h2>
              <span> Não Sincronizando </span>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="title bold">Programação do Dia</div>
      </div>
      <div class="card-body">
        <div class="row mb-1">
          <div
            class="form-group col-md-3"
            v-if="$store.state.user.access_level != 4"
          >
            <label for="centrals"> {{ $t("Central") }}: </label>
            <v-select
              :options="centers"
              :reduce="(centers) => (centers ? centers.id : null)"
              :label="'name'"
              v-model="center_id"
              class="vselect"
              v-on:input="changetUnitsCenter"
            />
          </div>
          <div
            class="form-group col-md-3"
            v-if="$store.state.user.access_level != 4"
          >
            <label for="unit"> {{ $t("Unit") }}: </label>

            <v-select
              :options="units"
              :reduce="(units) => (units ? units.id : null)"
              :label="'name'"
              v-model="unit_id"
              class="vselect"
              v-on:input="getEntity"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="filter"> Filtrar por Status</label>

            <v-select
              :options="[
                { label: 'Em posição', id: 1 },
                { label: 'Em remoção', id: 3 },
                { label: 'Finalizado', id: 2 },
                { label: 'Não iniciado', id: 0 },
              ]"
              :reduce="(options) => (options ? options.id : null)"
              v-model="filterStatus_"
              class="vselect"
              v-on:input="getEntity"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="filter"> Filtrar por Status do Dispositivo </label>

            <v-select
              :options="['Sincronizando', 'Não Sincronizando']"
              v-model="filterStatus"
              class="vselect"
              v-on:input="getEntity"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="filter"> Filtrar por data </label>
            <input
              type="date"
              v-model="filterDate"
              v-on:input="getEntity"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-1">
            <router-link
              to="/mobile_position/videowall"
              class="btn btn-primary"
              @click="autoRefreshing"
            >
              Mapa
            </router-link>
          </div>
          <div class="col-md-2" v-if="!auto_refresh">
            <button class="btn btn-info" @click="getEntity">
              Atualizar <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-md-3" v-if="!auto_refresh">
            <button class="btn btn-success" @click="autoRefreshing">
              Atualizar Automaticamente <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-md-1" v-else>
            <button class="btn btn-danger" @click="autoRefreshing">
              Parar <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-md-3" v-if="auto_refresh">
            Proxima atualização em {{ timeLeft }} segundos
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Usuário</th>
                  <th scope="col">Unidade/Área</th>
                  <th scope="col">Status</th>
                  <th scope="col">Data Início Agenda</th>
                  <th scope="col">Data Início Real</th>
                  <th scope="col">Data Fim Agenda</th>
                  <th scope="col">Data Fim Real</th>
                  <th scope="col">Última Posição</th>
                  <th scope="col">Status Dispositivo</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="activity in activities" :key="activity.id">
                  <th scope="row">{{ activity.id }}</th>
                  <td>
                    {{ activity.user.name }}<br />
                    ({{ activity.user.email }})
                  </td>

                  <td>
                    {{ activity.vehicle.unit.name }} <br />
                    <br />
                    ({{ activity.area.name }})
                  </td>
                  <td>
                    <span :class="badge(activity.status).class">{{
                      badge(activity.status).text
                    }}</span>
                  </td>
                  <td>{{ activity.start_date }}</td>
                  <td>{{ activity.actual_start_date }}</td>
                  <td>{{ activity.end_date }}</td>
                  <td>{{ activity.actual_end_date }}</td>
                  <td>{{ activity.last_position }}</td>
                  <td>
                    <span
                      :class="badgePosition(activity.status_position).class"
                      >{{ badgePosition(activity.status_position).text }}</span
                    >
                  </td>
                  <td>
                    <button
                      v-if="$store.state.user.access_level != 4"
                      class="btn btn-crud edit"
                      @click="restart(activity)"
                    >
                      <i class="fas fa-retweet"></i>
                    </button>
                    <br />
                    <button class="btn btn-crud info" @click="map(activity)">
                      <i class="fas fa-map-marker-alt"></i>
                    </button>
                    <br />
                    <button
                      class="btn btn-crud view"
                      @click="removals(activity)"
                    >
                      <i class="fas fa-ambulance"></i>
                    </button>
                    <br />
                    <button
                      v-if="$store.state.user.access_level != 4"
                      class="btn btn-crud delete"
                      @click="finish(activity)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Mapa -->
    <div
      class="modal fade"
      id="modalMap"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalMapTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Informações do dispositivo
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="data_modal.position">
            <div class="row centered">
              <div class="col-md-2">
                <div class="bold">Bateria do Dispositivo</div>
                <div>
                  <i
                    :class="batteryIcon(data_modal.data.battery_info)"
                    class="fa fa-3x"
                  ></i>
                  <span>
                    <p>{{ data_modal.data.battery_info }}</p>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="bold">Versão do Android</div>
                <div>
                  <i class="fab fa-android fa-3x"></i>
                  <span>
                    <p>{{ data_modal.data.device_version }}</p>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="bold">Modelo do Dispositivo</div>
                <div>
                  <i class="fas fa-mobile-alt fa-2x mb-1"></i>
                  <span>
                    <p>{{ data_modal.data.device_name }}</p>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="bold">IP do Dispositivo</div>
                <div>
                  <i class="fas fa-network-wired fa-2x mb-1"></i>
                  <span>
                    <p>{{ data_modal.data.device_ip }}</p>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="bold">Última Posição</div>
                <div>
                  <i class="fas fa-clock fa-2x mb-1"></i>
                  <span>
                    <p>{{ data_modal.data.position_date }}</p>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <GmapMap
                  :zoom="19"
                  :center="data_modal.position.coord"
                  style="width: 100%; height: 650px"
                >
                  <GmapMarker
                    :position="data_modal.position.coord"
                    style="width: 100px"
                  />
                </GmapMap>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Mapa -->
    <div
      class="modal fade"
      id="modalRemovals"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalRemovalsTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Remoções</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Número Ficha</th>
                  <th scope="col">Destino</th>
                  <th scope="col">Usuário</th>
                  <th scope="col">Veículo</th>
                  <th scope="col">Início da Remoção</th>
                  <th scope="col">Fim da Remoção</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="removal in data_modal_removal" :key="removal.id">
                  <th scope="row">{{ removal.id }}</th>
                  <td>{{ removal.card_number }}</td>
                  <td>{{ removal.destination_hospital }}</td>
                  <td>{{ removal.user.name }}</td>
                  <td>{{ removal.vehicle.name }}</td>
                  <td>{{ removal.start_date }}</td>
                  <td>{{ removal.end_date }}</td>
                  <td>
                    <span
                      :class="
                        removal.status == 1
                          ? 'badge badge-success'
                          : 'badge badge-warning'
                      "
                      >{{
                        removal.status == 1 ? "Finalizado" : "Iniciado"
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeLeft: 20,
      intervalId: null,
      center_id: null,
      unit_id: null,
      filterStatus: null,
      filterStatus_: null,
      intervalIdChronometer: null,
      filterDate: null,
      auto_refresh: false,
      activities: [],
      centers: [],
      units: [],
      data_modal_removal: [],
      vehicleslist: [], // Lista de veículos a ser exibida no mapa
      data_modal: {},
      dashboard: {},
      selectedVehicle: {},
      filters: {},
    };
  },
  components: {},
  computed: {
    optionsWithAll() {
      // Adiciona a opção "Todos" no início da lista
      return [{ id: null, name: "Todos" }, ...this.vehicleslist];
    },
  },
  methods: {
    batteryIcon(batteryLevel) {
      batteryLevel = batteryLevel.replace("%", "");
      if (batteryLevel > 80) return "fa-battery-full color-success";
      if (batteryLevel > 60) return "fa-battery-three-quarters color-success";
      if (batteryLevel > 40) return "fa-battery-half color-warning";
      if (batteryLevel > 20) return "fa-battery-quarter color-warning";
      if (batteryLevel < 20) return "fa-battery-quarter color-danger";
      return "fa-battery-empty";
    },
    badge(id) {
      switch (id) {
        case 0:
          return {
            text: "Não iniciado",
            class: "badge badge-warning",
          };
          break;
        case 1:
          return {
            text: "Em posição",
            class: "badge badge-info",
          };
          break;
        case 2:
          return {
            text: "Finalizado",
            class: "badge badge-success",
          };
          break;
        case 3:
          return {
            text: "Em remoção",
            class: "badge badge-danger",
          };
          break;
        default:
          break;
      }
    },
    badgePosition(id) {
      switch (id) {
        case true:
          return {
            text: "Sincronizando",
            class: "badge badge-success",
          };
          break;
        case false:
          return {
            text: "Offline",
            class: "badge badge-danger",
          };
          break;
        default:
          return {
            text: "Offline",
            class: "badge badge-secondary",
          };
          break;
      }
    },
    getEntity() {
      const self = this;
      let api = self.$store.state.api + "get-activities";
      self.$loading(true);

      self.filters = {
        unit_id: self.unit_id,
        status: self.filterStatus,
        status_: self.filterStatus_,
        date: self.filterDate,
      };

      axios
        .post(api, self.filters)
        .then((response) => {
          self.activities = response.data;
          self.$loading(false);
          self.getDashboard();
        })
        .catch((error) => {
          self.$error(error);
          this.$loading(false);
        });
    },
    getDashboard() {
      const self = this;
      let api = self.$store.state.api + "get-activities/dashboard";

      self.filters = {
        unit_id: self.unit_id,
      };

      axios
        .post(api, self.filters)
        .then((response) => {
          self.dashboard = response.data;
        })
        .catch((error) => {
          self.$error(error);
          self.$loading(false);
        });
    },
    restart(props) {
      const self = this;
      let api = self.$store.state.api + "restart_activity/" + props.id;

      swal({
        title: "Deseja reiniciar a atividade?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "A atividade não foi reiniciada!", "info");
            break;

          case "confirm":
            axios
              .post(api)
              .then((response) => {
                swal(
                  "Sucesso",
                  "A atividade foi reiniciada e o operador ja pode realizar login!",
                  "success"
                );
                self.getEntity();
              })
              .catch((error) => {
                console.error(error);
              });
            break;
        }
      });
    },
    map(props) {
      const self = this;
      let api = self.$store.state.api + "posicao-mobile/position";

      axios
        .post(api, props)
        .then((response) => {
          self.data_modal = response.data[0];

          self.data_modal.position.coord.lat = Number(
            self.data_modal.position.coord.lat
          );
          self.data_modal.position.coord.lng = Number(
            self.data_modal.position.coord.lng
          );

          $("#modalMap").modal("show");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    removals(props) {
      const self = this;
      let api = self.$store.state.api + "get-activities/removals";

      axios
        .post(api, props)
        .then((response) => {
          self.data_modal_removal = response.data;

          $("#modalRemovals").modal("show");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    finish(props) {
      const self = this;
      let api = self.$store.state.api + "end_activity";

      swal({
        title: "Deseja encerrar a atividade?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "A atividade não foi encerrada!", "info");
            break;

          case "confirm":
            axios
              .post(api, props)
              .then((response) => {
                self.data_modal_removal = response.data;
                self.getEntity();
              })
              .catch((error) => {
                console.error(error);
              });
            break;
        }
      });
      vs;
    },
    autoRefreshing() {
      const self = this;
      self.startCountdown();

      if (!self.auto_refresh) {
        self.intervalId = setInterval(() => {
          self.getEntity();
          self.startCountdown();
        }, 20000);
      } else {
        clearInterval(self.intervalId);
      }

      self.auto_refresh = !self.auto_refresh;
    },
    startCountdown() {
      clearInterval(this.intervalIdChronometer);
      this.timeLeft = 20;

      this.intervalIdChronometer = setInterval(() => {
        this.timeLeft--;

        if (this.timeLeft < 0) {
          this.timeLeft = 20;
        }
      }, 1000);
    },
    changetUnitsCenter(center_id) {
      this.unit_id = "";
      if (center_id) {
        this.getUnitsCenter(center_id);
      }
      this.filterVehicles();
    },
    getUnitsCenter(center_id) {
      const api = `${this.$store.state.api}centers/unit/${center_id}`;
      axios
        .get(api)
        .then((response) => {
          this.units = [{ id: null, name: "Todos" }, ...response.data];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    async getVehicles() {
      try {
        const api = this.$store.state.api + "vehicles";
        const response = await axios.get(api);
        console.log("Resposta completa da API:", response); // Verifica a estrutura da resposta completa

        // Verifique se os dados realmente existem antes de tentar acessá-los
        if (response.data) {
          this.result = response.data.data;
          this.vehicleslist = response.data.data;
          console.log("Dados da API (result):", this.result); // Verifica se `this.result` está correto
          // this.vehicles =response.data.data
          // this.vehicles = this.formatVehicleData(this.result);
          // console.log("Veículos formatados:", this.vehicles); // Verifica a lista de veículos formatados
          // this.updateCenter(this.vehicles);
        } else {
          console.warn(
            "A resposta da API não contém 'data.data'. Verifique a estrutura da resposta."
          );
          this.result = []; // Definindo como array vazio para evitar erros posteriores
        }
      } catch (error) {
        console.error("Erro ao obter veículos:", error);
      }
    },
    filterVehicles() {
      let filteredVehicles = this.result;

      // Filtra por unidade e centro se ambos estiverem definidos
      if (this.center_id && this.unit_id) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) =>
            vehicle.center_id === this.center_id &&
            vehicle.unit_id === this.unit_id
        );
      }
      // Filtra apenas pelo centro se somente `center_id` estiver definido
      else if (this.center_id) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) => vehicle.center_id === this.center_id
        );
      }
      // Filtra apenas pelo veículo selecionado se `selectedVehicle` estiver definido
      else if (this.selectedVehicle) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) => vehicle.id === this.selectedVehicle
        );
      }
      this.vehicleslist = filteredVehicles;
    },
    getCenters() {
      let api = this.$store.state.api + "centers/";
      axios
        .get(api)
        .then((response) => {
          this.centers = [{ id: null, name: "Todos" }, ...response.data.data];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const self = this;

    self.getCenters();
    self.getVehicles(); // Carrega os dados ao montar o componente
    self.getEntity();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.color-success {
  color: green;
}
.color-warning {
  color: rgb(212, 185, 62);
}
.color-danger {
  color: red;
}
</style>
