import Crud from '../views/RemovalManual/Crud'
import Entity from '../views/RemovalManual/Add'

let routes = {
    path: '/removals',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/removals/add',
            name: 'NewRemovalManual',
            component: Entity,
            meta: {
                pageTitle: 'Remoções',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/removals'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/removals/edit/:id?',
            name: 'EditRemovalManual',
            component: Entity,
            meta: {
                pageTitle: 'Remoções',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/removals'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/removals',
            name: 'list-removals',
            component: Crud,
            meta: {
                pageTitle: 'Remoções',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
