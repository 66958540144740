var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[(!_vm.companyId)?_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"company"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Company"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"company","options":_vm.companies,"reduce":function (company) { return company.id; },"label":'name'},on:{"input":_vm.changeCenterCompany},model:{value:(_vm.users.company_id),callback:function ($$v) {_vm.$set(_vm.users, "company_id", $$v)},expression:"users.company_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Centrals","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"centrals"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Centrals"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"centrals","options":_vm.centers,"reduce":function (centers) { return centers.id; },"label":'name'},on:{"input":_vm.changetUnitsCenter},model:{value:(_vm.users.center_id),callback:function ($$v) {_vm.$set(_vm.users, "center_id", $$v)},expression:"users.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"unit"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Unit"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"unit","options":_vm.units,"reduce":function (units) { return units.id; },"label":'name'},model:{value:(_vm.users.unit_id),callback:function ($$v) {_vm.$set(_vm.users, "unit_id", $$v)},expression:"users.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Name"))+": ")]),_c('b-form-input',{attrs:{"id":"Name","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.users.name),callback:function ($$v) {_vm.$set(_vm.users, "name", $$v)},expression:"users.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Email"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Email"))+": ")]),_c('b-form-input',{attrs:{"id":"Email","type":"email","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.users.email),callback:function ($$v) {_vm.$set(_vm.users, "email", $$v)},expression:"users.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Taxpayer Identification Number (CPF)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"cpf"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Taxpayer Identification Number (CPF)"))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('999.999.999-99'),expression:"'999.999.999-99'"}],attrs:{"id":"cpf","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.users.cpf_cnpj),callback:function ($$v) {_vm.$set(_vm.users, "cpf_cnpj", $$v)},expression:"users.cpf_cnpj"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t("Taxpayer Identification Number (CPF)") + " " + _vm.$t("Required Fields")))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('b-form-group',{attrs:{"label-for":"rg","label":_vm.$t('ID Card')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('99.999.999-9'),expression:"'99.999.999-9'"}],attrs:{"id":"rg","type":"text","autocomplete":"new-password","placeholder":""},model:{value:(_vm.users.rg),callback:function ($$v) {_vm.$set(_vm.users, "rg", $$v)},expression:"users.rg"}})],1)],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Date Of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"CellDateBrith"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Date Of Birth"))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('99/99/9999'),expression:"'99/99/9999'"}],attrs:{"id":"CellDateBrith","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.users.date_birth),callback:function ($$v) {_vm.$set(_vm.users, "date_birth", $$v)},expression:"users.date_birth"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('b-form-group',{attrs:{"label-for":"Cellphone","label":_vm.$t('Cell Phone')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(99) 99999-9999'),expression:"'(99) 99999-9999'"}],attrs:{"id":"Cellphone","type":"text","autocomplete":"new-password","placeholder":""},model:{value:(_vm.users.cellphone),callback:function ($$v) {_vm.$set(_vm.users, "cellphone", $$v)},expression:"users.cellphone"}})],1)],1),_c('div',{staticClass:"form-group col-md-4"},[_c('b-form-group',{attrs:{"label-for":"Phone","label":_vm.$t('Telephone')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(99) 9999-9999'),expression:"'(99) 9999-9999'"}],attrs:{"id":"Phone","type":"text","autocomplete":"new-password","placeholder":""},model:{value:(_vm.users.telephone),callback:function ($$v) {_vm.$set(_vm.users, "telephone", $$v)},expression:"users.telephone"}})],1)],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Access Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"inputUser"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Access Level"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"inputUser","options":_vm.profile_types,"reduce":function (profile_types) { return profile_types.id; },"label":'name'},model:{value:(_vm.users.access_level),callback:function ($$v) {_vm.$set(_vm.users, "access_level", $$v)},expression:"users.access_level"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),(_vm.$route.params.id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.toggleFormSenha}},[_c('i',{staticClass:"fas fa-key"}),_vm._v(" "+_vm._s(_vm.$t("Change Password"))+" ")])],1)]):_vm._e(),(_vm.change_password)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("password"))+": ")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"password","type":_vm.showPassword ? 'text' : 'password',"autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.users.password),callback:function ($$v) {_vm.$set(_vm.users, "password", $$v)},expression:"users.password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.toggleShowPassword}},[_c('i',{class:_vm.showPassword
                                      ? 'fa fa-eye-slash'
                                      : 'fa fa-eye'})])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Confirm The Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"confirmPassword"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Confirm The Password"))+": ")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"confirmPassword","type":_vm.showPassword ? 'text' : 'password',"autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.users.password_confirmacao),callback:function ($$v) {_vm.$set(_vm.users, "password_confirmacao", $$v)},expression:"users.password_confirmacao"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.toggleShowPassword}},[_c('i',{class:_vm.showPassword
                                      ? 'fa fa-eye-slash'
                                      : 'fa fa-eye'})])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"status"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status"))+": ")]),_c('b-form-select',{attrs:{"id":"status","state":errors.length ? false : null},model:{value:(_vm.users.status),callback:function ($$v) {_vm.$set(_vm.users, "status", $$v)},expression:"users.status"}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("Active")))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("Inactive")))])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group col-md-8 ml-n1"},[_c('b-form-group',{attrs:{"label-for":"userPic","label":_vm.$t('Profile Picture')}},[_c('div',{staticClass:"user-img"},[(_vm.previewImage)?_c('img',{staticClass:"photo",attrs:{"src":_vm.previewImage}}):_c('img',{staticClass:"photo"})]),_c('upload-file',{attrs:{"fileId":_vm.users.photo,"isImage":true,"size":"6rem"},on:{"uploaded":function (event) {
                              _vm.users.photo = event;
                            }}})],1)],1),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t("Required Fields")))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/users"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t("To Go Back"))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }