var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{class:_vm.getStatusClass(_vm.vehicle.level)},[_vm._v(" "+_vm._s(_vm.getStatusLabel(_vm.vehicle.level))+" ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Surname"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"Name","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.name),callback:function ($$v) {_vm.$set(_vm.vehicle, "name", $$v)},expression:"vehicle.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"License Plate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"license_plate"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("License Plate"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"license_plate","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.license_plate),callback:function ($$v) {_vm.$set(_vm.vehicle, "license_plate", $$v)},expression:"vehicle.license_plate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Frame","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"frame"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Frame"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"frame","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.frame),callback:function ($$v) {_vm.$set(_vm.vehicle, "frame", $$v)},expression:"vehicle.frame"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"National car registration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"national_registry"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("National car registration"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"national_registry","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.national_registry),callback:function ($$v) {_vm.$set(_vm.vehicle, "national_registry", $$v)},expression:"vehicle.national_registry"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicle Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle_types"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Vehicle Type"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"vehicle_types","options":_vm.vehicle_types,"reduce":function (vehicle_types) { return vehicle_types.id; },"label":'sigla'},on:{"input":_vm.getEspecialidade},model:{value:(_vm.vehicle.vehicle_type_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicle_type_id", $$v)},expression:"vehicle.vehicle_type_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Specialty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"specialty"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Specialty"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"specialty","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.specialty),callback:function ($$v) {_vm.$set(_vm.vehicle, "specialty", $$v)},expression:"vehicle.specialty"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle_colors"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Color"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicle_colors,"reduce":function (vehicle_colors) { return vehicle_colors.id; },"label":'name'},model:{value:(_vm.vehicle.color_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "color_id", $$v)},expression:"vehicle.color_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Vehicle Brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle_brands"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Vehicle Brand"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicle_brands,"reduce":function (vehicle_brands) { return vehicle_brands.id; },"label":'name'},model:{value:(_vm.vehicle.vehicle_brand_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicle_brand_id", $$v)},expression:"vehicle.vehicle_brand_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Vehicle Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle_models"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Vehicle Model"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.filteredVehicleModels,"reduce":function (vehicle_models) { return vehicle_models.id; },"label":'name'},model:{value:(_vm.vehicle.vehicle_model_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicle_model_id", $$v)},expression:"vehicle.vehicle_model_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Manufacture Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"manufacture_year"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("manufacture Year"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"manufacture_year","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.manufacture_year),callback:function ($$v) {_vm.$set(_vm.vehicle, "manufacture_year", $$v)},expression:"vehicle.manufacture_year"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Model Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"model_year"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("model Year"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"model_year","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.model_year),callback:function ($$v) {_vm.$set(_vm.vehicle, "model_year", $$v)},expression:"vehicle.model_year"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Telephone Pulsos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"telephone_pulsos"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Telephone Pulsos"))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(99) 99999-9999'),expression:"'(99) 99999-9999'"}],attrs:{"type":"text","id":"telephone_pulsos","placeholder":""},model:{value:(_vm.vehicle.telephone_pulsos),callback:function ($$v) {_vm.$set(_vm.vehicle, "telephone_pulsos", $$v)},expression:"vehicle.telephone_pulsos"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-5"},[_c('ValidationProvider',{attrs:{"name":"Centrals","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"centrals"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Centrals"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.centers,"reduce":function (centers) { return centers.id; },"label":'name'},on:{"input":_vm.changetUnitsCenter},model:{value:(_vm.vehicle.center_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "center_id", $$v)},expression:"vehicle.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-5"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"unit"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Unit"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.units,"reduce":function (units) { return units.id; },"label":'name'},model:{value:(_vm.vehicle.unit_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "unit_id", $$v)},expression:"vehicle.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"description"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Description"))+": ")]),_c('b-form-textarea',{attrs:{"id":"description","state":errors.length ? false : null,"placeholder":"","rows":"10"},model:{value:(_vm.vehicle.description),callback:function ($$v) {_vm.$set(_vm.vehicle, "description", $$v)},expression:"vehicle.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Maintenance Free","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"maintenance_free"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("maintenance free"))+": ")]),_c('b-form-select',{attrs:{"id":"maintenance_free","options":[
                              { value: 1, text: _vm.$t('Yes') },
                              { value: 0, text: _vm.$t('No') } ],"state":errors.length ? false : null},model:{value:(_vm.vehicle.maintenance_free),callback:function ($$v) {_vm.$set(_vm.vehicle, "maintenance_free", $$v)},expression:"vehicle.maintenance_free"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Checklist Free","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{attrs:{"for":"checklist_free"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("checklist free"))+": ")]),_c('b-form-select',{attrs:{"id":"checklist_free","options":[
                              { value: 1, text: _vm.$t('Yes') },
                              { value: 0, text: _vm.$t('No') } ],"state":errors.length ? false : null},model:{value:(_vm.vehicle.checklist_free),callback:function ($$v) {_vm.$set(_vm.vehicle, "checklist_free", $$v)},expression:"vehicle.checklist_free"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Mileage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{attrs:{"for":"mileage"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("mileage"))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"mileage","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.vehicle.mileage),callback:function ($$v) {_vm.$set(_vm.vehicle, "mileage", $$v)},expression:"vehicle.mileage"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{attrs:{"for":"status"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.status,"reduce":function (status) { return status.id; },"label":'name',"disabled":true},model:{value:(_vm.vehicle.status),callback:function ($$v) {_vm.$set(_vm.vehicle, "status", $$v)},expression:"vehicle.status"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Insert CRLV"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{attrs:{"for":"crlv"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Insert CRLV"))+": ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.vehicle.crlv},on:{"uploaded":function (event) {
                                _vm.vehicle.crlv = event;
                              }}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t("Required Fields"))+" ")])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/vehicles"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t("To Go Back"))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }