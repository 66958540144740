<template>
  <div>
    <div class="main-content">
      <div class="card">
        <div class="card-body">
          <section>
            <div class="row mb-2 mt-3">
              <div class="col-md-12 mt-1 text-right">
                <button class="btn btn-primary" @click="getLogsJson">
                  Verificar logs dessa atividade
                </button>
              </div>
            </div>
            <div class="row" v-if="logs_json.length">
              <div class="col-md-12">
                <table class="table table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 40%">Mensagem</th>
                      <th scope="col" style="width: 15%">Tipo</th>
                      <th scope="col" style="width: 15%">Usuário</th>
                      <th scope="col" style="width: 15%">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in logs_json" :key="log.id">
                      <td>{{ log ? log.message : "" }}</td>
                      <td>{{ log ? log.type : "" }}</td>
                      <td>{{ log ? log.user.name : "" }}</td>

                      <td>
                        <button class="btn btn-view" @click="showDetails(log)">
                          Exibir JSON
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-modal v-model="showModal" title="Detalhes do JSON" size="lg">
              <pre>{{ selectedLogJson }}</pre>
              <button class="btn btn-primary" @click="verMapa(selectedLogJson)">
                Ver Mapa
              </button>
            </b-modal>

            <b-modal v-model="showModalMap" title="Detalhes do JSON" size="xl">
              <div class="row">
                <div class="col-md-6">
                  <div class="title">Posição de Tentativa</div>
                  <GmapMap
                    :zoom="19"
                    :center="selectedLogJson"
                    style="width: 100%; height: 650px"
                  >
                    <GmapMarker
                      :position="selectedLogJson"
                      style="width: 100px"
                    />
                  </GmapMap>
                </div>
                <!-- <div class="col-md-6">
                  <div class="title bold mb-2">Área</div>
                  <GmapMap
                    :zoom="19"
                    :center="selectedLogJson"
                    style="width: 100%; height: 650px"
                  >
                    <GmapMarker
                      :position="selectedLogJson"
                      style="width: 100px"
                    />
                  </GmapMap>
                </div> -->
              </div>
            </b-modal>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import axios from "axios";

extend("required", required);

export default {
  data() {
    return {
      activity: {
        user_id: "",
        vehicle_id: "",
        area_id: "",
        status: 0,
        start_date: "",
        end_date: "",
        actual_start_date: "",
        actual_end_date: "",
      },
      users: [],
      areas: [],
      vehicles: [],
      errors: undefined,
      previewImage: null,
      logs_json: [],
      showModal: false,
      showModalMap: false,
      selectedLogJson: {},
    };
  },
  computed: {},
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    verMapa(obj) {
      this.showModal = false;
      this.showModalMap = true;
    },
    showDetails(log) {
      this.selectedLogJson = log.request_data;
      this.showModal = true;
    },
    getLogsJson() {
      const self = this;

      let api = self.$store.state.api + "logs_json?id=" + self.activity.id;

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.logs_json = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getActivities(id) {
      const self = this;
      const api = self.$store.state.api + "activities/" + id;

      axios
        .get(api)
        .then((response) => {
          self.activity = response.data.data[0];
        })
        .catch((error) => {
          self.$error(error);
        });
    },
    getUsers() {
      let api = this.$store.state.api + "users?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getAreas() {
      let api = this.$store.state.api + "areas?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.areas = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },

    getVehicles() {
      let api = this.$store.state.api + "vehicles?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.vehicles = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getUsers();
    self.getAreas();
    self.getVehicles();

    if (id) {
      self.getActivities(id);
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
};
</script>
