<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div :class="getStatusClass(vehicle.level)">
              {{ getStatusLabel(vehicle.level) }}
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Surname"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="Name">
                              <span class="required">*</span>
                              {{ $t("Surname") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="Name"
                              v-model="vehicle.name"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="License Plate"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="license_plate">
                              <span class="required">*</span>
                              {{ $t("License Plate") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="license_plate"
                              v-model="vehicle.license_plate"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Frame"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="frame">
                              <span class="required">*</span> {{ $t("Frame") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="frame"
                              v-model="vehicle.frame"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="National car registration"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="national_registry">
                              <span class="required">*</span>
                              {{ $t("National car registration") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="national_registry"
                              v-model="vehicle.national_registry"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider
                            name="Vehicle Type"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="vehicle_types">
                              <span class="required">*</span>
                              {{ $t("Vehicle Type") }}:
                            </label>

                            <v-select
                              id="vehicle_types"
                              :options="vehicle_types"
                              :reduce="(vehicle_types) => vehicle_types.id"
                              :label="'sigla'"
                              v-model="vehicle.vehicle_type_id"
                              class="vselect"
                              v-on:input="getEspecialidade"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider
                            name="Specialty"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="specialty">
                              <span class="required">*</span>
                              {{ $t("Specialty") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="specialty"
                              v-model="vehicle.specialty"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider
                            name="Color"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="vehicle_colors">
                              <span class="required">*</span> {{ $t("Color") }}:
                            </label>

                            <v-select
                              :options="vehicle_colors"
                              :reduce="(vehicle_colors) => vehicle_colors.id"
                              :label="'name'"
                              v-model="vehicle.color_id"
                              class="vselect"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Vehicle Brand"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="vehicle_brands">
                              <span class="required">*</span>
                              {{ $t("Vehicle Brand") }}:
                            </label>

                            <v-select
                              :options="vehicle_brands"
                              :reduce="(vehicle_brands) => vehicle_brands.id"
                              :label="'name'"
                              v-model="vehicle.vehicle_brand_id"
                              class="vselect"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Vehicle Model"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="vehicle_models">
                              <span class="required">*</span>
                              {{ $t("Vehicle Model") }}:
                            </label>

                            <v-select
                              :options="filteredVehicleModels"
                              :reduce="(vehicle_models) => vehicle_models.id"
                              :label="'name'"
                              v-model="vehicle.vehicle_model_id"
                              class="vselect"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Manufacture Year"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="manufacture_year">
                              <span class="required">*</span>
                              {{ $t("manufacture Year") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="manufacture_year"
                              v-model="vehicle.manufacture_year"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Model Year"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="model_year">
                              <span class="required">*</span>
                              {{ $t("model Year") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="model_year"
                              v-model="vehicle.model_year"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-2">
                          <ValidationProvider
                            name="Telephone Pulsos"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="telephone_pulsos">
                              <span class="required">*</span>
                              {{ $t("Telephone Pulsos") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="telephone_pulsos"
                              v-model="vehicle.telephone_pulsos"
                              placeholder=""
                              v-mask="'(99) 99999-9999'"
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-5">
                          <ValidationProvider
                            name="Centrals"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="centrals">
                              <span class="required">*</span>
                              {{ $t("Centrals") }}:
                            </label>
                            <v-select
                              :options="centers"
                              :reduce="(centers) => centers.id"
                              :label="'name'"
                              v-model="vehicle.center_id"
                              class="vselect"
                              v-on:input="changetUnitsCenter"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-5">
                          <ValidationProvider
                            name="Unit"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="unit">
                              <span class="required">*</span> {{ $t("Unit") }}:
                            </label>

                            <v-select
                              :options="units"
                              :reduce="(units) => units.id"
                              :label="'name'"
                              v-model="vehicle.unit_id"
                              class="vselect"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-12">
                          <ValidationProvider
                            name="Description"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="description">
                              <span class="required">*</span>
                              {{ $t("Description") }}:
                            </label>
                            <b-form-textarea
                              id="description"
                              v-model="vehicle.description"
                              :state="errors.length ? false : null"
                              placeholder=""
                              rows="10"
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Maintenance Free"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="maintenance_free">
                              <span class="required">*</span>
                              {{ $t("maintenance free") }}:
                            </label>
                            <b-form-select
                              id="maintenance_free"
                              v-model="vehicle.maintenance_free"
                              :options="[
                                { value: 1, text: $t('Yes') },
                                { value: 0, text: $t('No') },
                              ]"
                              :state="errors.length ? false : null"
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Checklist Free"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="checklist_free">
                              <span class="required">*</span>
                              {{ $t("checklist free") }}:
                            </label>
                            <b-form-select
                              id="checklist_free"
                              v-model="vehicle.checklist_free"
                              :options="[
                                { value: 1, text: $t('Yes') },
                                { value: 0, text: $t('No') },
                              ]"
                              :state="errors.length ? false : null"
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Mileage"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="mileage">
                              <span class="required">*</span>
                              {{ $t("mileage") }}:
                            </label>
                            <b-form-input
                              type="text"
                              id="mileage"
                              v-model="vehicle.mileage"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>{{
                              $t(errors[0])
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider
                            name="Status"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label for="status">
                              <span class="required">*</span>
                              {{ $t("Status") }}:
                            </label>
                            <v-select
                              class="vselect"
                              :options="status"
                              :reduce="(status) => status.id"
                              v-model="vehicle.status"
                              :label="'name'"
                              :disabled="true"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-12">
                          <ValidationProvider
                            name="Insert CRLV"
                            v-slot="{ errors }"
                          >
                            <label for="crlv">
                              <span class="required">*</span>
                              {{ $t("Insert CRLV") }}:
                            </label>
                            <upload-file
                              :fileId="vehicle.crlv"
                              @uploaded="
                                (event) => {
                                  vehicle.crlv = event;
                                }
                              "
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{
                              $t(errors[0])
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t("Required Fields") }} </strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/vehicles" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i>
                          {{ $t("To Go Back") }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t("Save") }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import UploadFile from "../../layouts/components/UploadFile";

extend("required", required);

const LEVEL = {
  0: "Register",
  1: "Active",
  2: "Inactive",
  3: "Available",
  5: "Off",
};

const LEVEL_CLASS = {
  0: "badge bg-primary",
  1: "badge bg-success",
  2: "badge badge-secondary",
  3: "badge badge-warning",
  5: "badge badge-danger",
};

export default {
  data() {
    return {
      vehicle: {
        status: 0,
        level: 0,
        name: "",
        license_plate: "",
        frame: "",
        national_registry: "",
        vehicle_type_id: "",
        specialty: "",
        color_id: "",
        vehicle_brand_id: "",
        vehicle_model_id: "",
        manufacture_year: "",
        model_year: "",
        center_id: "",
        unit_id: "",
        description: "",
        maintenance_free: "",
        checklist_free: "",
        mileage: "",
        crlv: "",
        telephone_pulsos: "",
      },
      vehicle_types: [],
      vehicle_colors: [],
      vehicle_brands: [],
      vehicle_models: [],
      centers: [],
      units: [],
      status: [
        {
          name: this.$t("Free"),
          id: 0,
        },
        {
          name: this.$t("Courtyard"),
          id: 1,
        },
        {
          name: this.$t("Occurrence"),
          id: 2,
        },
        {
          name: this.$t("Under maintenance"),
          id: 3,
        },
      ],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {
    filteredVehicleModels: function () {
      let newListModels = !this.vehicle.vehicle_brand_id
        ? []
        : this.vehicle_models.filter(
            (models) =>
              models.vehicle_brand_id === this.vehicle.vehicle_brand_id
          );
      this.vehicle.vehicle_model_id = newListModels.length
        ? newListModels[0].id
        : "";
      return newListModels;
    },
    filteredVehicleUnits: function () {
      return this.units;
    },
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    changetUnitsCenter(center_id) {
      this.vehicle.unit_id = "";
      this.getUnitsCenter(center_id);
    },
    getEspecialidade(id) {
      this.vehicle.specialty = "";
      if (id) {
        this.vehicle.specialty = this.vehicle_types.find(
          (type) => type.id == id
        ).name;
      }
    },
    getStatusLabel(index) {
      return this.$t(LEVEL[index]);
    },
    getStatusClass(index) {
      return LEVEL_CLASS[index];
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("name", self.vehicle.name);
      fd.append("license_plate", self.vehicle.license_plate);
      fd.append("frame", self.vehicle.frame);
      fd.append("national_registry", self.vehicle.national_registry);
      fd.append("vehicle_type_id", self.vehicle.vehicle_type_id);
      fd.append("specialty", self.vehicle.specialty);
      fd.append("color_id", self.vehicle.color_id);
      fd.append("vehicle_brand_id", self.vehicle.vehicle_brand_id);
      fd.append("vehicle_model_id", self.vehicle.vehicle_model_id);
      fd.append("manufacture_year", self.vehicle.manufacture_year);
      fd.append("model_year", self.vehicle.model_year);
      fd.append("center_id", self.vehicle.center_id);
      fd.append(
        "description",
        self.vehicle.description ? self.vehicle.description : ""
      );
      fd.append("maintenance_free", self.vehicle.maintenance_free);
      fd.append("checklist_free", self.vehicle.checklist_free);
      fd.append("unit_id", self.vehicle.unit_id);
      fd.append("mileage", self.vehicle.mileage);
      fd.append("status", self.vehicle.status);
      fd.append("level", self.vehicle.level);
      fd.append("crlv", self.vehicle.crlv);
      fd.append("telephone_pulsos", self.vehicle.telephone_pulsos);

      fd.append("_method", "POST");

      if (self.vehicle.id) {
        fd.append("id", self.vehicle.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "vehicles";
      let fd = self.makeFormData();

      if (self.vehicle.id) {
        self.vehicle._method = "PUT";
        api += "/" + self.vehicle.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            this.$t("Success"),
            this.$t("Information saved successfully"),
            "success"
          );
          this.$router.push("/vehicles");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getVehicle: function (id) {
      const self = this;
      const api = self.$store.state.api + "vehicles/" + id;

      axios
        .get(api)
        .then((response) => {
          self.vehicle = response.data.data[0];
          self.getUnitsCenter(self.vehicle.center_id);
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getVehicleTypes: function () {
      const self = this;
      const api = self.$store.state.api + "vehicle_types/";

      axios
        .get(api)
        .then((response) => {
          self.vehicle_types = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getVehicleColors: function () {
      const self = this;
      const api = self.$store.state.api + "vehicle_colors/";

      axios
        .get(api)
        .then((response) => {
          self.vehicle_colors = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getVehicleBrands: function () {
      const self = this;
      const api = self.$store.state.api + "vehicle_brands/";

      axios
        .get(api)
        .then((response) => {
          self.vehicle_brands = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getVehicleModels: function () {
      const self = this;
      const api = self.$store.state.api + "vehicle_models/";

      axios
        .get(api)
        .then((response) => {
          self.vehicle_models = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCenters: function () {
      const self = this;
      const api = self.$store.state.api + "centers/";

      axios
        .get(api)
        .then((response) => {
          self.centers = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getUnitsCenter: function (center_id) {
      const self = this;
      const api = `${self.$store.state.api}centers/unit/${center_id}`;
      axios
        .get(api)
        .then((response) => {
          self.units = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getVehicleTypes();
    self.getVehicleColors();
    self.getVehicleBrands();
    self.getVehicleModels();
    self.getCenters();

    if (id) {
      self.getVehicle(id);
    }
  },
  components: {
    vSelect,
    UploadFile,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
  },
};
</script>
    
    <style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
    