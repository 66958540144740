<template>
  <div>
    <div class="main-content">
      <div class="card">
        <div class="card-body">
          <ValidationObserver ref="formObserver" v-slot="{ validate }">
            <b-form @submit.prevent="handleSubmit(validate)">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <ValidationProvider
                    name="card_number"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="card_number">
                      <span class="required">*</span>
                      {{ $t("Número da Ficha") }}:
                    </label>
                    <b-form-input
                      id="card_number"
                      type="text"
                      v-model="removal_manual.card_number"
                      autocomplete="new-password"
                      :state="errors.length ? false : null"
                      placeholder=""
                    />
                    <b-form-invalid-feedback :state="errors.length">{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-4">
                  <ValidationProvider
                    name="Users"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="Name">
                      <span class="required">*</span>
                      {{ $t("Hospital de Destino") }}:
                    </label>
                    <v-select
                      id="user"
                      :options="destinos"
                      :reduce="(destino) => destino.destino_id"
                      label="destino_nome"
                      v-model="removal_manual.destination_hospital"
                      class="vselect"
                      :class="{ 'is-invalid': errors.length }"
                    />

                    <b-form-invalid-feedback>{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-4">
                  <ValidationProvider
                    name="Users"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="Name">
                      <span class="required">*</span>
                      {{ $t("Atividades") }}:
                    </label>
                    <v-select
                      id="user"
                      :options="activities"
                      :reduce="(activity) => activity.id"
                      label="formatted_name"
                      v-model="removal_manual.activity_id"
                      class="vselect"
                      :class="{ 'is-invalid': errors.length }"
                    />

                    <b-form-invalid-feedback>{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <!-- <div class="form-group col-md-4">
                  <ValidationProvider
                    name="Vehicles"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="Name">
                      <span class="required">*</span>
                      {{ $t("Vehicles") }}:
                    </label>
                    <v-select
                      id="vehicle"
                      :options="vehicles"
                      :reduce="(vehicles) => vehicles.id"
                      :label="'name'"
                      v-model="removal_manual.vehicle_id"
                      class="vselect"
                      :class="{ 'is-invalid': errors.length }"
                    />
                    <b-form-invalid-feedback>{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div> -->
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <ValidationProvider
                    name="start_date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="start_date">
                      <span class="required">*</span> {{ $t("Data Inicio") }}:
                    </label>
                    <b-form-input
                      id="start_date"
                      type="datetime-local"
                      v-model="removal_manual.start_date"
                      autocomplete="new-password"
                      :state="errors.length ? false : null"
                      placeholder=""
                    />
                    <b-form-invalid-feedback :state="errors.length">{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-6">
                  <ValidationProvider
                    name="end_date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="end_date">
                      <span class="required">*</span> {{ $t("Data fim") }}:
                    </label>
                    <b-form-input
                      id="end_date"
                      type="datetime-local"
                      v-model="removal_manual.end_date"
                      autocomplete="new-password"
                      :state="errors.length ? false : null"
                      placeholder=""
                    />
                    <b-form-invalid-feedback :state="errors.length">{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <span class="required_fields">
                <span class="required">*</span>
                <strong> {{ $t("Required Fields") }}</strong>
              </span>
              <div class="block text-right">
                <router-link to="/removals" class="btn btn-back">
                  <i class="fa fa-arrow-left"></i> {{ $t("To Go Back") }}
                </router-link>
                <b-button type="submit" variant="primary" class="ml-1">
                  {{ $t("Save") }}
                  <i class="fa fa-save"></i>
                </b-button>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import axios from "axios";

extend("required", required);

export default {
  data() {
    return {
      removal_manual: {
        card_number: "",
        destination_hospital: "",
        status: 1,
        start_date: "",
        end_date: "",
      },
      removals: [],
      errors: undefined,
      previewImage: null,
      destinos: [],
      activities: [],
    };
  },
  computed: {},
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      const self = this;
      let api = self.$store.state.api + "removal_manuals";

      if (self.removal_manual.id) {
        self.removal_manual._method = "PUT";
        api += "/" + self.removal_manual.id;
      }

      axios
        .post(api, self.removal_manual)
        .then(() => {
          self.$message(
            this.$t("Success"),
            this.$t("Information saved successfully"),
            "success"
          );
          self.$router.push("/removals");
        })
        .catch((error) => {
          self.$error(error);
        });
    },
    getRemovalManual(id) {
      const self = this;
      const api = self.$store.state.api + "removals/" + id;

      axios
        .get(api)
        .then((response) => {
          self.removal_manual = response.data.data[0];
        })
        .catch((error) => {
          self.$error(error);
        });
    },
    getUsers() {
      let api = this.$store.state.api + "users?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getActivities() {
      let api =
        this.$store.state.api +
        "activities?paginated=false&status=1&" +
        "with[]=user";
      axios
        .get(api)
        .then((response) => {
          this.activities = response.data.data.map((activity) => ({
            ...activity,
            formatted_name: ` ${activity.user?.name || "Sem usuário"}`,
          }));
        })
        .catch((error) => {
          this.$error(error);
        });
    },

    getVehicles() {
      let api = this.$store.state.api + "vehicles?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.vehicles = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getDestino() {
      let api = this.$store.state.api + "destination_hospital?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.destinos = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getUsers();
    self.getActivities();
    self.getVehicles();
    self.getDestino();

    if (id) {
      self.getRemovalManual(id);
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
};
</script>
