var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Users","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Users"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"user","options":_vm.users,"reduce":function (users) { return users.id; },"label":'name'},model:{value:(_vm.activity.user_id),callback:function ($$v) {_vm.$set(_vm.activity, "user_id", $$v)},expression:"activity.user_id"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Areas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Areas"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"user","options":_vm.areas,"reduce":function (areas) { return areas.id; },"label":'name'},model:{value:(_vm.activity.area_id),callback:function ($$v) {_vm.$set(_vm.activity, "area_id", $$v)},expression:"activity.area_id"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Vehicles"))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"vehicle","options":_vm.vehicles,"reduce":function (vehicles) { return vehicles.id; },"label":'name'},model:{value:(_vm.activity.vehicle_id),callback:function ($$v) {_vm.$set(_vm.activity, "vehicle_id", $$v)},expression:"activity.vehicle_id"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"start_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("start_date"))+": ")]),_c('b-form-input',{attrs:{"id":"start_date","type":"datetime-local","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.activity.start_date),callback:function ($$v) {_vm.$set(_vm.activity, "start_date", $$v)},expression:"activity.start_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"end_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"end_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("end_date"))+": ")]),_c('b-form-input',{attrs:{"id":"end_date","type":"datetime-local","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.activity.end_date),callback:function ($$v) {_vm.$set(_vm.activity, "end_date", $$v)},expression:"activity.end_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Required Fields")))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/activities"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t("To Go Back"))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }