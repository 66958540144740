<template>
  <div class="card">
    <div class="card-header">
      <div class="title bold">Logs</div>
    </div>
    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Usuário</th>
            <th scope="col">Veículo</th>
            <th scope="col">Logs</th>
            <th scope="col">Data/Hora</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs_positions" :key="log.id">
            <th scope="row">{{ log.id }}</th>
            <td>{{ log.user ? log.user.name : "" }}</td>
            <td>{{ log.vehicle ? log.vehicle.name : "" }}</td>
            <td>{{ log.logs }}</td>
            <td>{{ log.created_at_f }}</td>
            <td>
              <button class="btn btn-view" @click="showDetails(log)">
                Exibir JSON
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal v-model="showModal" title="Detalhes do JSON" size="lg">
      <pre>{{ selectedLogJson }}</pre>
      <button class="btn btn-primary" @click="verMapa(selectedLogJson)">
        Ver Mapa
      </button>
    </b-modal>
    <b-modal v-model="showModalMap" title="Detalhes do JSON" size="xl">
      <div class="row">
        <div class="col-md-12">
          <div class="row centered">
            <div class="col-md-2">
              <div class="bold">Bateria do Dispositivo</div>
              <div>
                <i
                  :class="batteryIcon(selectedLogJsonInfos.battery_info)"
                  class="fa fa-3x"
                ></i>
                <span>
                  <p>{{ selectedLogJsonInfos.battery_info }}</p>
                </span>
              </div>
            </div>
            <div class="col-md-2">
              <div class="bold">Versão do Android</div>
              <div>
                <i class="fab fa-android fa-3x"></i>
                <span>
                  <p>{{ selectedLogJsonInfos.device_version }}</p>
                </span>
              </div>
            </div>
            <div class="col-md-2">
              <div class="bold">Modelo do Dispositivo</div>
              <div>
                <i class="fas fa-mobile-alt fa-2x mb-1"></i>
                <span>
                  <p>{{ selectedLogJsonInfos.device_name }}</p>
                </span>
              </div>
            </div>
            <div class="col-md-2">
              <div class="bold">IP do Dispositivo</div>
              <div>
                <i class="fas fa-network-wired fa-2x mb-1"></i>
                <span>
                  <p>{{ selectedLogJsonInfos.device_ip }}</p>
                </span>
              </div>
            </div>
            <div class="col-md-2">
              <div class="bold">Versão APP</div>
              <div>
                <i class="fas fa-clock fa-2x mb-1"></i>
                <span>
                  <p>{{ selectedLogJsonInfos.version_app }}</p>
                </span>
              </div>
            </div>
          </div>
          <div class="title">Posição de Tentativa</div>
          <GmapMap
            :zoom="19"
            :center="selectedLogJson"
            style="width: 100%; height: 650px"
          >
            <GmapMarker :position="selectedLogJson" style="width: 100px" />
          </GmapMap>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
export default {
  data() {
    return {
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      pages: [],
      logs_positions: [],
      showModal: false,
      showModalMap: false,
      selectedLogJson: {},
      selectedLogJsonInfos: {},
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
  computed: {},
  methods: {
    batteryIcon(batteryLevel) {
      if (!batteryLevel || typeof batteryLevel !== "string")
        return "fa-battery-empty";
      batteryLevel = batteryLevel.replace("%", "");
      batteryLevel = parseInt(batteryLevel, 10);

      if (isNaN(batteryLevel)) return "fa-battery-empty";

      if (batteryLevel > 80) return "fa-battery-full color-success";
      if (batteryLevel > 60) return "fa-battery-three-quarters color-success";
      if (batteryLevel > 40) return "fa-battery-half color-warning";
      if (batteryLevel > 20) return "fa-battery-quarter color-warning";
      return "fa-battery-empty color-danger";
    },
    showDetails(log) {
      this.selectedLogJson = {
        lat: parseFloat(log.lat),
        lng: parseFloat(log.long),
      };
      this.showModal = true;
      this.selectedLogJsonInfos = {
        battery_info: log.battery_info,
        device_ip: log.device_ip,
        device_name: log.device_name,
        device_version: log.device_version,
        version_app: log.version_app,
      };
      console.log("dsadas", this.selectedLogJsonInfos);
    },
    verMapa(obj) {
      this.showModal = false;
      this.showModalMap = true;
    },
    getLogs() {
      const self = this;

      let api = self.$store.state.api + "logs_positions";

      self.$loading(true);
      axios
        .post(api, self.filters)
        .then((response) => {
          self.logs_positions = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
  },
  mounted() {
    const self = this;

    self.getLogs();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.color-success {
  color: green;
}
.color-warning {
  color: rgb(212, 185, 62);
}
.color-danger {
  color: red;
}
</style>