<template>
  <div>
    <div class="main-content">
      <div class="card">
        <div class="card-body">
          <ValidationObserver ref="formObserver" v-slot="{ validate }">
            <b-form @submit.prevent="handleSubmit(validate)">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <ValidationProvider
                    name="Users"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="Name">
                      <span class="required">*</span>
                      {{ $t("Users") }}:
                    </label>
                    <v-select
                      id="user"
                      :options="users"
                      :reduce="(users) => users.id"
                      :label="'name'"
                      v-model="activity.user_id"
                      class="vselect"
                      :class="{ 'is-invalid': errors.length }"
                    />
                    <b-form-invalid-feedback>{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-4">
                  <ValidationProvider
                    name="Areas"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="Name">
                      <span class="required">*</span>
                      {{ $t("Areas") }}:
                    </label>
                    <v-select
                      id="user"
                      :options="areas"
                      :reduce="(areas) => areas.id"
                      :label="'name'"
                      v-model="activity.area_id"
                      class="vselect"
                      :class="{ 'is-invalid': errors.length }"
                    />
                    <b-form-invalid-feedback>{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-4">
                  <ValidationProvider
                    name="Vehicles"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="Name">
                      <span class="required">*</span>
                      {{ $t("Vehicles") }}:
                    </label>
                    <v-select
                      id="vehicle"
                      :options="vehicles"
                      :reduce="(vehicles) => vehicles.id"
                      :label="'name'"
                      v-model="activity.vehicle_id"
                      class="vselect"
                      :class="{ 'is-invalid': errors.length }"
                    />
                    <b-form-invalid-feedback>{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <ValidationProvider
                    name="start_date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="start_date">
                      <span class="required">*</span> {{ $t("start_date") }}:
                    </label>
                    <b-form-input
                      id="start_date"
                      type="datetime-local"
                      v-model="activity.start_date"
                      autocomplete="new-password"
                      :state="errors.length ? false : null"
                      placeholder=""
                    />
                    <b-form-invalid-feedback :state="errors.length">{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-6">
                  <ValidationProvider
                    name="end_date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="end_date">
                      <span class="required">*</span> {{ $t("end_date") }}:
                    </label>
                    <b-form-input
                      id="end_date"
                      type="datetime-local"
                      v-model="activity.end_date"
                      autocomplete="new-password"
                      :state="errors.length ? false : null"
                      placeholder=""
                    />
                    <b-form-invalid-feedback :state="errors.length">{{
                      $t(errors[0])
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <span class="required_fields">
                <span class="required">*</span>
                <strong> {{ $t("Required Fields") }}</strong>
              </span>
              <div class="block text-right">
                <router-link to="/activities" class="btn btn-back">
                  <i class="fa fa-arrow-left"></i> {{ $t("To Go Back") }}
                </router-link>
                <b-button type="submit" variant="primary" class="ml-1">
                  {{ $t("Save") }}
                  <i class="fa fa-save"></i>
                </b-button>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import axios from "axios";

extend("required", required);

export default {
  data() {
    return {
      activity: {
        user_id: "",
        vehicle_id: "",
        area_id: "",
        status: 0,
        start_date: "",
        end_date: "",
        actual_start_date: "",
        actual_end_date: "",
      },
      users: [],
      areas: [],
      vehicles: [],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      const self = this;
      let api = self.$store.state.api + "activities";

      if (self.activity.id) {
        self.activity._method = "PUT";
        api += "/" + self.activity.id;
      }

      axios
        .post(api, self.activity)
        .then(() => {
          self.$message(
            this.$t("Success"),
            this.$t("Information saved successfully"),
            "success"
          );
          self.$router.push("/activities");
        })
        .catch((error) => {
          self.$error(error);
        });
    },
    getActivities(id) {
      const self = this;
      const api = self.$store.state.api + "activities/" + id;

      axios
        .get(api)
        .then((response) => {
          self.activity = response.data.data[0];
        })
        .catch((error) => {
          self.$error(error);
        });
    },
    getUsers() {
      let api = this.$store.state.api + "users?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getAreas() {
      let api = this.$store.state.api + "areas?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.areas = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },

    getVehicles() {
      let api = this.$store.state.api + "vehicles?paginated=false";
      axios
        .get(api)
        .then((response) => {
          this.vehicles = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getUsers();
    self.getAreas();
    self.getVehicles();

    if (id) {
      self.getActivities(id);
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
};
</script>
