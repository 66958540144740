<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="title bold">Mapa</div>
      </div>
      <div class="card-body">
        <div class="row mb-1" v-if="$store.state.user.access_level != 4">
          <div class="form-group col-md-3">
            <label for="centrals"> {{ $t("Central") }}: </label>
            <v-select
              :options="centers"
              :reduce="(centers) => (centers ? centers.id : null)"
              :label="'name'"
              v-model="center_id"
              class="vselect"
              v-on:input="changetUnitsCenter"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="unit"> {{ $t("Unit") }}: </label>

            <v-select
              :options="units"
              :reduce="(units) => (units ? units.id : null)"
              :label="'name'"
              v-model="unit_id"
              class="vselect"
              v-on:input="getEntity"
            />
          </div>
          <!-- <div class="form-group col-md-3">
            <label for="filter"> Filtrar por veículo </label>

            <v-select
              :options="optionsWithAll"
              :reduce="(vehicle) => (vehicle ? vehicle.id : null)"
              :label="'name'"
              v-model="selectedVehicle"
              class="vselect"
              v-on:input="getEntity"
            />
          </div> -->
        </div>
        <div class="row mb-2">
          <div class="col-md-2" v-if="!auto_refresh">
            <button class="btn btn-info" @click="getEntity">
              Atualizar <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-md-3" v-if="!auto_refresh">
            <button class="btn btn-success" @click="autoRefreshing">
              Atualizar Automaticamente <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-md-1" v-else>
            <button class="btn btn-danger" @click="autoRefreshing">
              Parar <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-md-3" v-if="auto_refresh">
            Proxima atualização em {{ timeLeft }} segundos
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <GmapMap
              :zoom="13"
              :center="currentLocation"
              style="width: 100%; height: 650px"
            >
              <GmapMarker
                v-if="loaded"
                v-for="(position, index) in data"
                :key="index"
                :position="position.position.coord"
                :icon="{
                  url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                  scaledSize: { width: 50, height: 40 },
                }"
                style="width: 100px"
                @click="selectedMarker = position"
              />
              <GmapInfoWindow
                v-if="selectedMarker"
                :position="selectedMarker.position.coord"
                :opened="true"
                @closeclick="selectedMarker = null"
              >
                <div style="width: 370px">
                  <h2>{{ selectedMarker.data.vehicle.name }}</h2>
                  <br />
                  <h5>
                    Bateria do Dispositivo:
                    {{ selectedMarker.data.battery_info }}
                  </h5>
                  <h5>
                    Última Atualização: {{ selectedMarker.data.position_date }}
                  </h5>
                  <h5>
                    Central: {{ selectedMarker.data.vehicle.center.name }}
                  </h5>
                  <h5>Unidade: {{ selectedMarker.data.vehicle.unit.name }}</h5>
                </div>
              </GmapInfoWindow>
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeLeft: 20,
      intervalId: null,
      intervalIdChronometer: null,
      auto_refresh: false,
      data: {},
      dashboard: {},
      currentLocation: {},
      selectedMarker: null,
      loaded: false,
      selectedVehicle: {},
      filters: {},
      center_id: null,
      unit_id: null,
      centers: [],
      units: [],
    };
  },
  components: {},
  computed: {},
  methods: {
    getCurrentLocation() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log("Localização atual:", this.currentLocation);
          },
          (error) => {
            console.error("Erro ao obter localização:", error);
            this.error = "Não foi possível acessar a localização.";
          }
        );
      } else {
        this.error = "Geolocalização não suportada pelo navegador.";
      }
    },
    getEntity() {
      const self = this;
      let api = self.$store.state.api + "posicao-mobile/position";

      self.loaded = false;

      self.filters = {
        unit_id: self.unit_id,
      };

      axios
        .post(api, self.filters)
        .then((response) => {
          self.data = response.data;

          self.data.forEach((element) => {
            console.log(element);
            element.position.coord.lat = Number(element.position.coord.lat);
            element.position.coord.lng = Number(element.position.coord.lng);
          });

          setTimeout(() => {
            self.loaded = true;
          }, 500);
          self.$loading(false);
        })
        .catch((error) => {
          self.$error(error);
          this.$loading(false);
        });
    },
    autoRefreshing() {
      const self = this;
      self.startCountdown();

      if (!self.auto_refresh) {
        self.intervalId = setInterval(() => {
          self.getEntity();
          self.startCountdown();
        }, 20000);
      } else {
        clearInterval(self.intervalId);
      }

      self.auto_refresh = !self.auto_refresh;
    },
    startCountdown() {
      clearInterval(this.intervalIdChronometer);
      this.timeLeft = 20;

      this.intervalIdChronometer = setInterval(() => {
        this.timeLeft--;

        if (this.timeLeft < 0) {
          this.timeLeft = 20;
        }
      }, 1000);
    },
    getCenters() {
      let api = this.$store.state.api + "centers/";
      axios
        .get(api)
        .then((response) => {
          this.centers = [{ id: null, name: "Todos" }, ...response.data.data];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    async getVehicles() {
      try {
        const api = this.$store.state.api + "vehicles";
        const response = await axios.get(api);
        console.log("Resposta completa da API:", response); // Verifica a estrutura da resposta completa

        // Verifique se os dados realmente existem antes de tentar acessá-los
        if (response.data) {
          this.result = response.data.data;
          this.vehicleslist = response.data.data;
          console.log("Dados da API (result):", this.result); // Verifica se `this.result` está correto
          // this.vehicles =response.data.data
          // this.vehicles = this.formatVehicleData(this.result);
          // console.log("Veículos formatados:", this.vehicles); // Verifica a lista de veículos formatados
          // this.updateCenter(this.vehicles);
        } else {
          console.warn(
            "A resposta da API não contém 'data.data'. Verifique a estrutura da resposta."
          );
          this.result = []; // Definindo como array vazio para evitar erros posteriores
        }
      } catch (error) {
        console.error("Erro ao obter veículos:", error);
      }
    },
    filterVehicles() {
      let filteredVehicles = this.result;

      // Filtra por unidade e centro se ambos estiverem definidos
      if (this.center_id && this.unit_id) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) =>
            vehicle.center_id === this.center_id &&
            vehicle.unit_id === this.unit_id
        );
      }
      // Filtra apenas pelo centro se somente `center_id` estiver definido
      else if (this.center_id) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) => vehicle.center_id === this.center_id
        );
      }
      // Filtra apenas pelo veículo selecionado se `selectedVehicle` estiver definido
      else if (this.selectedVehicle) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) => vehicle.id === this.selectedVehicle
        );
      }
      this.vehicleslist = filteredVehicles;
    },
    changetUnitsCenter(center_id) {
      this.unit_id = "";
      if (center_id) {
        this.getUnitsCenter(center_id);
      }
      this.filterVehicles();
    },
    getUnitsCenter(center_id) {
      const api = `${this.$store.state.api}centers/unit/${center_id}`;
      axios
        .get(api)
        .then((response) => {
          this.units = [{ id: null, name: "Todos" }, ...response.data];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const self = this;

    self.getEntity();
    self.getCurrentLocation();
    self.getCenters();
    self.getVehicles(); // Carrega os dados ao montar o componente
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.color-success {
  color: green;
}
.color-warning {
  color: rgb(212, 185, 62);
}
.color-danger {
  color: red;
}
</style>
