import Crud from '../views/Activity/Crud'
import Entity from '../views/Activity/Add'
import View from '../views/Activity/View'

let routes = {
    path: '/activities',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/activities/add',
            name: 'NewActivity',
            component: Entity,
            meta: {
                pageTitle: 'Activities',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/activities'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/activities/edit/:id?',
            name: 'EditActivity',
            component: Entity,
            meta: {
                pageTitle: 'Activities',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/activities'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/activities/view/:id?',
            name: 'ViewActivity',
            component: View,
            meta: {
                pageTitle: 'Activities',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/activities'
                    },
                    {
                        text: 'View',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/activities',
            name: 'list-activities',
            component: Crud,
            meta: {
                pageTitle: 'Activities',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
