import Crud from '../views/LogMobilePosition/Table'
import View from '../views/LogMobilePosition/View'

let routes = {
    path: '/logs_positions',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/logs_positions',
            name: 'logs_positions',
            component: Crud,
            meta: {
                pageTitle: 'Logs das Posições (Nova Api)',
                access_level: ['1', '2', '3', '4'],
                breadcrumb: [
                    {
                        text: 'Logs das Posições',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/logs_positions/view/:id?',
            name: 'ViewLogMobilePosition',
            component: View,
            meta: {
                pageTitle: 'Logs das Posições',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/logs_positions'
                    },
                    {
                        text: 'View',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
